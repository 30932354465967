import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

export const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
export const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
export const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
export const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
export const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
export const PageSix = Loadable(lazy(() => import('../pages/PageSix')));

export const ListRequest = Loadable(lazy(() => import('../pages/Request/ListRequest')));
export const UpdateRequest = Loadable(lazy(() => import('../pages/Request/UpdateRequest')));
export const CreateRequest = Loadable(lazy(() => import('../pages/Request/CreateRequest')));


export const ListCandidate = Loadable(lazy(() => import('../pages/Candidate/ListCandidate')));
export const CreateCandidate = Loadable(lazy(() => import('../pages/Candidate/CreateCandidate')));

export const ListUser = Loadable(lazy(() => import('../pages/User/ListUser')));
export const CreateUser = Loadable(lazy(() => import('../pages/User/CreateUser')));



export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
