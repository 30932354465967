// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  // one: path(ROOTS_DASHBOARD, '/one'),
  // two: path(ROOTS_DASHBOARD, '/two'),
  // three: path(ROOTS_DASHBOARD, '/three'),
  
  request: {
    list: path(ROOTS_DASHBOARD, '/request/list'),
    update: path(ROOTS_DASHBOARD, '/request/update'),
    create: path(ROOTS_DASHBOARD, '/request/create'),
  },
  candidate:{
    list: path(ROOTS_DASHBOARD, '/candidate/list'),
    create: path(ROOTS_DASHBOARD, '/candidate/create'),
  },
  user: {
    list: path(ROOTS_DASHBOARD, '/user/list'),
    create: path(ROOTS_DASHBOARD, '/user/create'),
   
  },
};
